.App {
  text-align: left;
  background-color: #f1ece7;
}

.App-logo {
  height: 2vh;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.nav-bar-color,
.footer-color {
  background-color: #000;
}

.login-btn,
.logout-btn {
  background-color: #000;
  color: #fff;
}

input[type='text'] {
  color: #000;
}

.button-color {
  background-color: #0396ec;
}

.text-purple {
  color: #723c70;
}

.text-black {
  color: #000;
}

@font-face {
  font-family: 'Canela-Black';
  src: local('Canela-Black'), url('./fonts/Canela-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Mythshire';
  src: url('./fonts/Mythshire.otf') format('opentype');
}

@font-face {
  font-family: 'Neuton-Bold';
  src: local('Neuton-Bold'), url('./fonts/Neuton-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Neuton-Italic';
  src: local('Neuton-Italic'), url('./fonts/Neuton-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Bold';
  src: local('Raleway-Bold'), url('./fonts/Raleway-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-BoldItalic';
  src: local('Raleway-BoldItalic'), url('./fonts/Raleway-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-ExtraBold';
  src: local('Raleway-ExtraBold'), url('./fonts/Raleway-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-ExtraBoldItalic';
  src: local('Raleway-ExtraBoldItalic'), url('./fonts/Raleway-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-ExtraLight';
  src: local('Raleway-ExtraLight'), url('./fonts/Raleway-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-ExtraLightItalic';
  src: local('Raleway-ExtraLightItalic'), url('./fonts/Raleway-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Italic-VariableFont';
  src: local('Raleway-Italic-VariableFont_wght'), url('./fonts/Raleway-Italic-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Italic';
  src: local('Raleway-Italic'), url('./fonts/Raleway-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Light';
  src: local('Raleway-Light'), url('./fonts/Raleway-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-LightItalic';
  src: local('Raleway-LightItalic'), url('./fonts/Raleway-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Medium';
  src: local('Raleway-Medium'), url('./fonts/Raleway-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-MediumItalic';
  src: local('Raleway-MediumItalic'), url('./fonts/Raleway-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Regular';
  src: local('Raleway-Regular'), url('./fonts/Raleway-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-SemiBold';
  src: local('Raleway-SemiBold'), url('./fonts/Raleway-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-SemiBoldItalic';
  src: local('Raleway-SemiBoldItalic'), url('./fonts/Raleway-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Thin';
  src: local('Raleway-Thin'), url('./fonts/Raleway-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-ThinItalic';
  src: local('Raleway-ThinItalic'), url('./fonts/Raleway-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-VariableFont';
  src: local('Raleway-VariableFont_wght'), url('./fonts/Raleway-VariableFont_wght.ttf') format('truetype');
}

.club-description,
.club-faq {
  background-color: #f2ca86;
  font-family: Raleway-Medium;
  padding-top: 3rem;
}

.club-description-container,
.club-faq-container {
  max-width: 60%;
  margin: auto;
}

.sub-banner {
  font-family: Raleway-Medium;
}

.wizard {
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: Raleway-Medium;
  padding-bottom: 2rem;
}

.wizard h2 {
  font-family: 'Neuton-Bold';
}

.wizard-nav {
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: #f8ae20;
  border-radius: 30px;
  font-family: 'Neuton-Bold';
}

.wizard-nav li {
  border-radius: 30px;
}

.wizard-nav li.selected {
  background-color: #ce415f;
}

.wizard-nav li.completed:hover {
  background-color: #f2ca86;
  cursor: pointer;
}

.wizard-nav li .wine-glass {
  margin-top: 3px;
}

.footer-container {
  color: #fff;
  font-family: Raleway-Light;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Raleway-Bold;
}

.bg-salvaje-orange {
  background-color: #f8ae1f;
}

.hero-banner {
  background-image: url('./resources/hero-banner.webp');
  background-size: cover;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 0;
  height: 450px;
}

.sub-banner-text {
  color: #723c70;
  font-family: Raleway-Bold;
}

.pkg-casual,
.pkg-classic-two-bottles-bkgnd {
  background-color: #f8ae1f;
  font-family: Raleway-Bold;
}

.pkg-casual .details {
  background-color: #f3ca86;
  background-image: url('./resources/pkg-classic-two-bottles-footer.png');
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  font-family: Raleway-SemiBold;
}

.pkg-classic-step-two-details {
  background-color: #f3ca86;
}

.pkg-classic,
.pkg-classic-three-bottles-bkgnd {
  background-color: #ce4260;
  font-family: Raleway-Bold;
}

.pkg-classic .details {
  background-color: #efbfbe;
  background-image: url('./resources/pkg-two-footer.png');
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  font-family: Raleway-SemiBold;
}

.pkg-premium {
  background-color: #e5e0ba;
  font-family: Raleway-Bold;
}

.pkg-premium .details {
  background-color: #ccc79d;
  background-image: url('./resources/pkg-three-footer.png');
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  font-family: Raleway-SemiBold;
}

.checkout-details {
  background-color: #f4c986;
  background-image: url('./resources/premium-checkout-background.png');
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position-y: 100%;
  background-position-x: 50%;
}

.checkout-details .description {
  font-family: 'Neuton-Italic';
  margin: 0 auto;
  width: 60%;
}

.checkout-summary {
  background-color: #f39518;
}

.checkout-summary .checkout-btn {
  border: 2px solid black;
  border-radius: 30px;
  background-color: #ce4161;
  font-family: 'Neuton-Bold';
}

.summary-container {
  background-color: #fbf9f5;
  border: 2px solid #000;
  font-family: 'Neuton-Bold';
}

.summary-header {
  color: #8b1837;
  margin-bottom: 15px;
}

.summary-details-data {
  font-family: 'Raleway-Medium';
}

.summary-footnote {
  margin: 0 auto;
  width: 48%;
}

.checkout-legend {
  font-family: 'Mythshire';
}

.faq-question {
  padding: 30px;
  border: 3px solid white;
  border-radius: 3px;
  font-size: 1.3rem;
}

.faq-question:hover,
.question-show-answer {
  background-color: white;
}

.answer-hidden {
  font-size: 0;
  line-height: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  /* fade out, then shrink */
  transition: font-size 0s, margin 0.5s, padding 0.5s, line-height 0.25s, opacity 0.25s;
}

.answer-display {
  transition: font-size 0s, margin 0.5s, padding 0.5s, opacity 0.5s 0.25s;
  font-size: 1.125rem;
  line-height: 2rem;
}

/**
* Success screen styles
*/
.success_container {
  position: absolute;
  top: 57px;
  bottom: 284px;
  width: 100%;
}

.success_screen {
  background-color: #f3ca86;
  font-family: Neuton-Italic;
  height: 100%;
}

.success_screen h2 {
  font-family: Neuton-Italic;
}

/**
* Checkout processing screen styles
*/
.processing_screen {
  background-color: #f3ca86;
  font-family: Neuton-Italic;
  height: 80vh;
}

/** 
* desktop displays from 1024×768 through 1920×1080
* mobile displays from 360×640 through 414×896
* tablet displays from 601×962 through 1280×800
**/
/* iPhone SE, 12, Samsung Galaxy */
@media (min-width: 300px) {
  .pkg-casual .details {
    background-size: 14%;
    background-position-y: 37px;
    background-position-x: 92%;
  }

  .pkg-classic .details {
    background-size: 18%;
    background-position-y: 34px;
    background-position-x: 100%;
  }

  .pkg-premium .details {
    background-size: 18%;
    background-position-y: 34px;
    background-position-x: 100%;
  }
  .club-description-container,
  .club-faq-container {
    max-width: 90%;
  }
}

/* iPhone XR */
@media (min-width: 410px) {
  .pkg-casual .details {
    background-size: 9%;
    background-position-y: 100%;
    background-position-x: 90%;
  }

  .pkg-classic .details {
    background-size: 12%;
    background-position-y: 34px;
    background-position-x: 100%;
  }

  .pkg-premium .details {
    background-size: 13%;
    background-position-y: 34px;
    background-position-x: 100%;
  }

  .club-description-container,
  .club-faq-container {
    max-width: 90%;
  }
}

@media (max-width: 414px) {
  .sub-banner-text {
    font-size: 1.5rem;
  }

  .sub-banner-img {
    padding-top: 35px;
  }

  .club-description-container,
  .club-faq-container {
    max-width: 90%;
  }
}

@media (min-width: 640px) {
  .pkg-casual .details {
    background-size: 6%;
    background-position-y: 100%;
    background-position-x: 90%;
  }

  .pkg-classic .details {
    background-size: 10%;
    background-position-y: 30px;
    background-position-x: 100%;
  }

  .pkg-premium .details {
    background-size: 10%;
    background-position-y: 34px;
    background-position-x: 100%;
  }
}

@media (min-width: 768px) {
  .pkg-casual .details {
    background-size: 11%;
    background-position-y: 100%;
    background-position-x: 96%;
  }

  .pkg-classic .details {
    background-size: 10%;
    background-position-y: 30px;
    background-position-x: 100%;
  }

  .pkg-premium .details {
    background-size: 18%;
    background-position-y: 34px;
    background-position-x: 100%;
  }
}

@media (min-width: 1024px) {
  .hero-banner {
    background-position-y: -5px;
  }

  .pkg-casual .details {
    background-size: 10%;
    background-position-y: 100%;
    background-position-x: 90%;
  }

  .pkg-classic .details {
    background-size: 16%;
    background-position-y: 30px;
    background-position-x: 100%;
  }

  .pkg-premium .details {
    background-size: 16%;
    background-position-y: 34px;
    background-position-x: 100%;
  }

  .club-description-container,
  .club-faq-container {
    max-width: 60%;
  }

  .breadcrumb-wrapper,
  .step-one-wrapper {
    max-width: 100%;
    margin: 0 auto;
  }

  .step-two-wrapper,
  .step-three-wrapper {
    max-width: 980px;
    margin: 0 auto;
  }
}

@media (min-width: 1280px) {
  .pkg-casual .details {
    background-size: 13%;
    background-position-y: 43px;
    background-position-x: 90%;
  }

  .pkg-classic .details {
    background-size: 20%;
    background-position-y: 37px;
    background-position-x: 100%;
  }

  .pkg-premium .details {
    background-size: 20%;
    background-position-y: 35px;
    background-position-x: 100%;
  }

  .breadcrumb-wrapper,
  .step-one-wrapper {
    max-width: 1260px;
    margin: 0 auto;
  }
}

@media (min-width: 1536px) {
  .hero-banner {
    background-position-y: -70px;
  }

  .pkg-casual .details {
    background-size: 8%;
    background-position-y: 40px;
    background-position-x: 90%;
  }

  .pkg-classic .details {
    background-size: 15%;
    background-position-y: 30px;
    background-position-x: 100%;
  }

  .pkg-premium .details {
    background-size: 13%;
    background-position-y: 34px;
    background-position-x: 100%;
  }
}

.swiper-overrides {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.swiper-slide-prev,
.swiper-slide-next {
  opacity: 0.5;
}

.breadcrumb-item {
  width: 30%;
  overflow-x: hidden;
  font-size: 17px;
}

.breadcrumb-item.selected {
  width: 45%;
  font-size: 20px;
}

.wizard-item-wrapper {
  height: 540px;
  overflow-y: hidden;
}

.step-two-wrapper .wizard-item-wrapper {
  background-color: #aea829;
}

.step-two-wrapper .wizard-item-wrapper {
  max-width: 450px;
}

.step-two-carousel .wizard-item-wrapper {
  margin: 0 auto;
}

@media (max-width: 600px) {
  .checkout-details {
    background-image: none;
  }

  .checkout-details.wizard-item-wrapper {
    height: 300px;
  }

  .checkout-details img {
    margin: 0 auto 20px;
    width: 80%;
  }

  .checkout-details .description {
    width: 100%;
  }

  .success_container {
    width: 100%;
    height: 70vh;
    position: inherit;
  }
}
